.App {
  font-family: sans-serif;
  text-align: center;
}

body {
  overflow: overlay;
}

html ::-webkit-scrollbar-track,
body ::-webkit-scrollbar-track {
  border-radius: 10px;
  background-color: transparent;
}

html ::-webkit-scrollbar,
body ::-webkit-scrollbar {
  width: 8px;
  background-color: transparent;
}

html ::-webkit-scrollbar-thumb,
body ::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: darkgray;
}
